import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container container-main" style={{ height: 300 }}>
      <h1>404: Not Found</h1>
      <p>This page doesn't exist!</p>
    </div>
  </Layout>
)

export default NotFoundPage
